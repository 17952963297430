
export default function downloadExcel(excelData, tHeader, filterVal, excelName) {
  require.ensure([], () => {
    let { export_json_to_excel } = require("./Export2Excel");
    let data = excelData.map(v => filterVal.map(j => v[j]));

    export_json_to_excel(tHeader, data, excelName); 
  });
}

