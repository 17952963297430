var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"czyyz"},[_c('el-popover',{attrs:{"trigger":"manual","placement":"bottom","width":"400"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"popups"},[_c('div',{staticClass:"nav"},[_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between","align-items":"center","margin-bottom":"20px","z-index":"30000"}}),_vm._l((_vm.list),function(item){return [(item.isMenu)?_c('div',{key:item.id,class:[
                'Navigation-box',
                item.id == 7 || item.id == 8 ? 'height-144' : 'height-100',
              ]},[_c('div',{staticClass:"title",on:{"click":function($event){return _vm.navigation(item)}}},[_c('span'),_c('span',[_vm._v(_vm._s(item.authName)),(_vm.redList.includes(item.authName))?_c('span',{staticClass:"red",staticStyle:{"margin-left":"6px"}},[_vm._v("·")]):_vm._e()]),_c('img',{attrs:{"src":item.imgSrc}})]),_c('div',{staticClass:"Navigation-list"},[_vm._l((item.children),function(i){return [(i.isMenu)?_c('div',{key:i.id,class:[
                      'Nav-item',
                      item.id == 7 || item.id == 8
                        ? 'height-25'
                        : 'height-33',
                    ],on:{"click":function($event){return _vm.topage(i)}}},[_c('span',[_vm._v(_vm._s(i.authName))])]):_vm._e()]})],2)]):_vm._e()]})],2)]),_c('span',{staticClass:"NavigationBut",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("导航")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }