import LuckyExcel from "luckyexcel";
export default function(file){
    return new Promise((res,rej)=>{
        LuckyExcel.transformExcelToLucky(file, function (exportJson) {
            // luckysheetFile=luckysheetfile
            if (exportJson.sheets == null || exportJson.sheets.length == 0) {
              alert("Failed to read the content of the excel file, currently does not support xls files!");
              return;
            }
           let data = exportJson.sheets[0].celldata;
           let obj = {};
           let dataObj = {}
           data.forEach(item => {
                if(item.r==0&&'v' in item.v){
                    obj[item.c] =item.v.v
                }else{
                    if(item.c in obj&&'v' in item.v){
                        if(dataObj[obj[item.c]]==undefined){
                            dataObj[obj[item.c]] = [];
                        }
                        dataObj[obj[item.c]].push({
                            v:item.v.v,
                            c:item.c+1,
                            r:item.r+1
                        });
                    }
                }
           });
           res(dataObj);
        })
    })
}  
