import CryptoJS from 'crypto-js';
import JSEncrypt from "jsencrypt";
import { Base64 } from "js-base64";
class RsaResult {
    constructor(aesKey, content) {
        this.aesKey = aesKey;
        this.content = content;
    }
}

function generateRandomHex() {
    var result = '';
    var characters = '0123456789abcdef';
    for (var i = 0; i < 16; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

class CryptoFiles {
    constructor() {
        this._code = generateRandomHex();
        // 秘钥
        this.key = CryptoJS.enc.Utf8.parse(this._code);
    }
    // 加密
    encrypt(word) {
        if(typeof(word) != 'string'){
            word =JSON.stringify(word);
        }
        let words = CryptoJS.enc.Utf8.parse(word);
        let encrypted = CryptoJS.AES.encrypt(words, this.key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString();
    }
    encryptRsa(word) {
        let encryptStr = new JSEncrypt();
        encryptStr.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC0q7be2rTTwyrJiRSJ9rWfP2rD2hdc0Knnp4xHKIKrvHwvkj8mg1aLMdAWyQdufronFPn6sjBWMTwaB6sTNQ4IozKfQ3BmdaZng8gsEoIIzzJiZ947Bnx8wgv5EKE5A7Q9VWjYqi51NxOSvULUBYidVcFj9s/jmxjNrceGcagFswIDAQAB")
         return  new RsaResult(encryptStr.encrypt(this._code), this.encrypt(word))
    }
    //二维码解密
    decryptRsa(word) {
        let encryptStr = new JSEncrypt();
        encryptStr.setPrivateKey("MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBALSrtt7atNPDKsmJFIn2tZ8/asPaF1zQqeenjEcogqu8fC+SPyaDVosx0BbJB25+uicU+fqyMFYxPBoHqxM1DgijMp9DcGZ1pmeDyCwSggjPMmJn3jsGfHzCC/kQoTkDtD1VaNiqLnU3E5K9QtQFiJ1VwWP2z+ObGM2tx4ZxqAWzAgMBAAECgYBth4hm1Go50yD2ay9TcIitEXI1Z9sv/qRydyXY4dtOKAeVg3DbdVYbLsi7d4YU26kZFmNyM2KQbmrEUQ/Kpihp1ykhK8B0MX+7kpX42eu+xnCNzrkhOp23igxWZ+A3pr7Yqx+9sfVMBLB9KTHgGtvYOdhKo0d6D+6eo47Z5utpcQJBAPTYRr8KaX3OPe8krgs8/RAgVwBBIyznYiM4x+BCGfRgbTWYVW200ThG4jJVB19n6EIVDDkJ/OheC7x/TBlYnQkCQQC85vMz9/zqueq+QlFOUQUBZWGJk6xez8Gyj8q+u6NCCIArTohQxY5b0zsGWcZbAwDGbV5zmmZopxAmiHpUJvfbAkAXLob8PYMhay6H/uQPolWfekBigMhQzxOGnDCodfm5HowYf/TOr0kD2ApAk7Zf/RmLcCy5Fc4BPi5ESZUt7SrpAkB3kFJ1HUynIT/0ZrNht6RMaufelWqvpB48sAiqyscdv5WoU4+yWCiv9DXS4jXLW049SfJTh3G0YbG2QgHzi6qrAkEAm6ALKDhaHHhKSwLYNAGxPqoqbujp2S0ek5LlGDT48IHSPRbmRsG99DWOfSCR4/qQPoJ/isp08iyaaloapPOoEw==")
        let aseKey = encryptStr.decrypt(word.aesKey).toString();
        return JSON.parse(CryptoJS.AES.decrypt(word.content,  CryptoJS.enc.Utf8.parse(aseKey), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoJS.enc.Utf8))
    }

    // 解密
    decrypt(word) {
        let decrypt = CryptoJS.AES.decrypt(word, this.key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }
}


// let res = CryptoFiles.decryptRsa({"aesKey":"D2gTIp0pfi1ebbBOfUPStloY+WZLTt/P2rrpwZailBkX6dTlv9V3DejW45YZmOiFEexJ5RyN7i0vVJ2+vQarKyTwZwvBxyyadp5Wrafh+1v53BSIjHsPFlelA0woNoQCwo0hUiK5XuV3AusdAbpvKf2DErTGfyXTjeX2dGQ5rSI=","content":"cLot1+KSt4mAeW2xobxhDAT6fa4WvnFLgDvEW2Iom1IO1BKAvozG8+lFWt4VjWLL4kTL4G7ZgtzjMn/60Xv43YnK1c3XTw+K8OWUz7OU30D/edereoty9fsrLO5XcUIR"})
  
export default new CryptoFiles()