export default function tree(menus, menu) {
    menu.children = [];
    let ids = [];
    menus.forEach(i => {
        if (i.pid == menu.id) {
            if('path' in i &&i.path!=null){
                i.name = i.path.substring(1)
                i.component = ()=> import(item.pcComponent);
            }
            menu.children.push(i);
            ids.push(i.id)
        }
    });
    menus = menus.filter(i=>{
        if(!ids.includes(i.id)){
            return i;
        }
    })
    if (menu.children.length > 0) {
        menu.children.forEach(i => tree(menus, i));
    }
}