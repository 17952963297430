
import JsPDF from 'jspdf'
export default function pdfDownload(that,ref) {
    let _this = that
    let myBox = ref; //获取ref里面的内容
    html2canvas(myBox, {
      useCORS: true, //是否尝试使用CORS从服务器加载图像
      allowTaint: true,
      dpi: 300, //解决生产图片模糊
      scale: 3, //清晰度--放大倍数
    }).then(function (canvas) {
      let contentWidth = canvas.width
      let contentHeight = canvas.height
      let pageHeight = contentWidth / 592.28 * 841.89 // 一页pdf显示html页面生成的canvas高度;
      let leftHeight = contentHeight //未生成pdf的html页面高度
      let position = 0 //pdf页面偏移
      //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      // let imgWidth = 595.28
      let imgWidth = 560.28  //宽度
      let imgHeight = 592.28 / contentWidth * contentHeight
      let pageData = canvas.toDataURL('image/jpeg', 1.0)
      let PDF = new JsPDF('', 'pt', 'a4')

      // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
      //当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < pageHeight) {
        PDF.addImage(pageData, 'JPEG', 20, 20, imgWidth, imgHeight)
      } else {
        while (leftHeight > 0) {
          PDF.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight)
          leftHeight -= pageHeight
          position -= 841.89
          if (leftHeight > 0) {
            PDF.addPage()
          }
        }
      }
      PDF.save('组织架构' + '.pdf')//下载标题
    });
  }