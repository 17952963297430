class ArcCode {
  constructor() {
    //考勤检验
    this.CheckType = {
      OnDuty: "上班",
      OffDuty: "下班",
    };
    //考勤检验
    this.timeResult = {
      Normal: "正常",
      Early: "早退",
      Late: "迟到",
      SeriousLate: "严重迟到",
      Absenteeism: "旷工迟到",
      NotSigned: "未打卡",
    };
    //表格跳转
    this.tableJump = {
      /**招聘 */
      recruit: {
        /**
         * 招聘计划
         */
        RECRUITING_PLAN: {
          //招聘计划
          tableNameArr: ["招聘计划表"],
          name: "招聘计划",
          dataArr: [
            "申请部门",
            "申请人",
            "需求部门",
            "申请岗位",
            "需求人数",
            "紧急程度",
            "希望到岗日期",
            "工作地点",
            "招聘渠道",
            "学历",
            "专业",
            "性别",
            "年龄",
            "工作经验",
            "语言要求",
            "资格证书",
            "专业知识",
            "岗位技能",
            "能力素质",
            "其他要求",
            "岗位职责",
            "预计招聘完成时间",
          ],
        },
      },
      /**薪酬 */
      salary: {
        /**
         * 薪酬预算
         */
        SALARY_BUDGET: {
          //
          tableNameArr: ["智能薪酬报表"],
          names: [
            {
              text: "薪酬预算",
            },
          ],
          dataArr: [
            "公司薪酬预算（万元）",
            "公司总体基本工资（万元）",
            "公司总体岗位工资（万元）",
            "公司总体绩效工资（万元）",
            "公司总体计件工资（万元）",
            "公司总体奖金（万元）",
            "公司总体加班费（万元）",
            "公司总体岗位津贴（万元）",
            "公司总体提成（万元）",
            "公司总体住宿补贴（万元）",
            "公司总体交通补贴（万元）",
            "公司总体通讯补贴（万元）",
            "预算占比",
            "薪酬支出（万元）",
            "人均收入（元）",
          ],
        },
        /**
         * 总额控制
         */
        TOTAL_AMOUNT_CONTROL: {
          tableNameArr: ["智能薪酬报表", "员工花名册"],
          names: [
            {
              text: "总额控制",
            },
          ],
          dataArr: [
            "公司薪酬总额（万元）",
            "公司总体基本工资（万元）",
            "公司总体绩效工资（万元）",
            "公司总体计件工资（万元）",
            "公司总体奖金（万元）",
            "公司总体加班费（万元）",
            "公司总体岗位津贴（万元）",
            "公司总体提成（万元）",
            "公司总体住宿补贴（万元）",
            "公司总体交通补贴（万元）",
            "公司总体通讯补贴（万元）",
            "备注",
          ],
        },
        /**
         * 薪等薪级
         */
        SALARY_LEVEL: {
          tableNameArr: ["智能薪酬报表"],
          names: [
            {
              text: "薪等薪级",
            },
          ],
          dataArr: [
            "中心",
            "部门",
            "岗位",
            "薪等",
            "薪级",
            "基本工资（元）",
            "岗位工资（元）",
            "绩效工资（元）",
            "补贴总额（元）",
            "工龄补贴（元）",
            "岗位津贴（元）",
            "项目奖金（元）",
            "月度奖金（元）",
            "季度奖金（元）",
            "年终奖金（元）",
          ],
        },
        /**
         * 定薪管理
         */
        SALARY_MANAGEMENT: {
          tableNameArr: ["智能薪酬报表"],
          names: [
            {
              text: "定薪管理",
            },
          ],
          dataArr: [
            "姓名",
            "中心",
            "部门",
            "岗位",
            "薪等",
            "薪级",
            "基本工资（元）",
            "岗位工资（元）",
            "绩效工资（元）",
            "补贴总额（元）",
            "工龄补贴（元）",
            "岗位津贴（元）",
            "项目奖金（元）",
            "月度奖金（元）",
            "季度奖金（元）",
            "年终奖金（元）",
          ],
          path: "/Workbench/WageApplication",
        },
        /**
         * 调薪管理
         */
        SALARY_ADJUSTMENT_MANAGEMENT: {
          tableNameArr: ["智能薪酬报表"],
          names: [
            {
              text: "调薪管理",
            },
          ],
          dataArr: [
            "姓名",
            "中心",
            "部门",
            "岗位",
            "薪等",
            "薪级",
            "基本工资（元）",
            "岗位工资（元）",
            "绩效工资（元）",
            "补贴总额（元）",
            "工龄补贴（元）",
            "岗位津贴（元）",
            "项目奖金（元）",
            "月度奖金（元）",
            "季度奖金（元）",
            "年终奖金（元）",
          ],
          path: "/Workbench/ApplicationForSalaryIncrease",
        },
        /**
         * 薪酬核算
         */
        SALARY_ACCOUNTING: {
          tableNameArr: ["员工花名册", "智能薪酬报表"],
          names: [
            {
              text: "薪酬核算",
            },
          ],
          dataArr: [
            "姓名",
            "部门",
            "岗位",
            "基本工资（元）",
            "岗位工资（元）",
            "绩效工资（元）",
            "出勤工资（元）",
            "计件工资（元）",
            "提成工资（元）",
            "全勤奖（元）",
            "补贴（元）",
            "加班费（元）",
            "工龄补贴（元）",
            "加班补贴（元）",
            "双薪补贴（元）",
            "岗位津贴（元）",
            "餐费补贴（元）",
            "其他津贴（元）",
            "保密费（元）",
            "项目奖金（元）",
            "月度奖金（元）",
            "季度奖金（元）",
            "奖励金额（元）",
            "考勤扣款（元）",
            "请假扣减（元）",
            "罚款金额（元）",
            "其他（元）",
            "应发工资（元）",
            "个税（元）",
            "社保（元）",
            "公积金（元）",
            "其他应扣金额（元）",
            "实发工资（元）",
          ],
        },
        /**
         * 奖金
         */
        BONUS: {
          tableNameArr: ["智能薪酬报表", "员工花名册"],
          names: [
            {
              text: "奖金",
            },
          ],
          dataArr: [
            "工号",
            "姓名",
            "部门",
            "岗位",
            "项目奖金（元）",
            "月度奖金（元）",
            "季度奖金（元）",
            "年终奖金（元）",
            "奖金总额（元）",
          ],
        },
        /**
         * 工资条
         */
        PAYSLIP: {
          tableNameArr: ["智能薪酬报表", "员工花名册"],
          names: [
            {
              text: "工资条",
            },
          ],
          dataArr: [
            "工号",
            "姓名",
            "部门",
            "岗位",
            "基本工资（元）",
            "岗位工资（元）",
            "绩效工资（元）",
            "出勤工资（元）",
            "计件工资（元）",
            "提成工资（元）",
            "全勤奖（元）",
            "补贴（元）",
            "加班费（元）",
            "工龄补贴（元）",
            "加班补贴（元）",
            "双薪补贴（元）",
            "岗位津贴（元）",
            "餐费补贴（元）",
            "其他津贴（元）",
            "保密费（元）",
            "项目奖金（元）",
            "月度奖金（元）",
            "季度奖金（元）",
            "奖励金额（元）",
            "考勤扣款（元）",
            "请假扣减（元）",
            "罚款金额（元）",
            "其他（元）",
            "应发工资（元）",
            "个税（元）",
            "社保（元）",
            "公积金（元）",
            "其他应扣金额（元）",
            "实发工资（元）",
          ],
        },
      },
      /**绩效 */
      performance: {
        /**
         * 战略解码
         */
        STRATEGIC_DECODING: {
          tableNameArr: ["战略解码"],
          names: [
            {
              text: "战略解码",
              data: "dialogVisible1", //StrategyDecoding
            },
          ],
          dataArr: [
            "类别",
            "名称",
            "目标值/内容描述",
            "要求完成时间",
            "到期完成情况",
            "到期完成率",
            "实际完成时间",
            "责任部门",
            "责任人",
            "协助部门",
            "协助人",
          ],
        },
        /**
         * 目标分解
         */
        OBJECTIVE_DECOMPOSITION: {
          tableNameArr: ["公司级目标管理报表"],
          names: [
            {
              text: "目标分解",
              data: "dialogVisible2",
            },
          ],
          dataArr: [
            "协助人",
            "公司级目标目标名称",
            "公司级目标值",
            "考核指标",
            "指标值",
            "指标定义/计算公式",
            "责任部门",
            "责任人",
            "协助部门",
            "要求完成时间",
            "到期完成情况",
            "实际完成时间",
            "到期完成率",
          ],
        },
        /**
         * 任务分解
         */
        TASK_DECOMPOSITION: {
          tableNameArr: ["公司级任务管理报表"],
          // tableNameArr: ["员工花名册", "智能绩效报表"],
          names: [
            {
              text: "任务分解",
              data: "dialogVisible3",
            },
          ],
          dataArr: [
            "协助人",
            "编号",
            "公司级关键任务名称",
            "公司级关键任务内容描述",
            "部门级关键任务名称",
            "部门级关键任务内容描述",
            "要求完成时间",
            "到期完成情况",
            "到期完成率",
            "实际完成时间",
            "任务状态",
            "是否按时完成",
            "责任部门",
            "责任人",
            "协助部门",
          ],
        },
        /**
         * 项目管理
         */
        PROJECT_MANAGEMENT: {
          tableNameArr: ["公司级项目管理报表"],
          names: [
            {
              text: "项目管理",
              data: "dialogVisible",
            },
          ],
          dataArr: [
            "编号",
            "公司级关键项目名称",
            "公司级关键项目内容描述",
            "部门级关键项目名称",
            "部门级关键项目内容描述",
            "要求完成时间",
            "到期完成情况",
            "到期完成率",
            "实际完成时间",
            "项目状态",
            "是否按时完成",
            "责任部门",
            "责任人",
            "协助部门",
            "协助人",
            "备注",
          ],
        },
        /**
         * 指标档案库
         */
        PERFORMANCE_INDICATOR_LIBRARY: {
          tableNameArr: ["指标档案库"],
          names: [
            {
              text: "指标档案库",
              data: "dialogVisible7",
            },
          ],
          dataArr: [
            "类别",
            "考核指标",
            "指标定义/计算公式",
            "指标值",
            "权重",
            "评分规则",
          ],
        },
      },
      /**培训 */
      training: {
        TRAINING_PLAN: {
          /**
           * 培训计划
           */
          tableNameArr: ["培训计划表"],
          name: "Train/trainingPlan",
          dataArr: [
            "计划人数",
            "培训级别",
            "培训日期",
            "课程名称",
            "培训地点",
            "培训类别",
            "培训讲师",
            "培训费用",
            "培训方式",
            "培训时数（小时）",
            "培训对象",
          ],
        },
      },
    };
    //人力菜单
    this.ManpowerMenu = [
      {
        text: "招聘",
        icon: "https://airencai.oss-cn-beijing.aliyuncs.com/20230808/2023/8/8/69603ac6c87541828fd31a7f3502571cimg (3).png",
        data: [
          [
            {
              path: "/Recruitment/RecruitmentApplication",
              icon: "el-icon-printer",
              title: "招聘申请",
            },
            {
              path: "/Recruitment/TalentPool",
              icon: "el-icon-document-remove",
              title: "招聘计划",
              name: "Recruitment/recruitingPlan",
              path: "/Recruitment/recruitingPlan",
            },
            {
              path: "/Recruitment/positionManagement",
              icon: "el-icon-date",
              title: "职位发布",
            },
          ],
          [
            { path: "/Recruitment/WisdomPrimary", title: "人员面试" },
            { path: "/Recruitment/install", title: "招聘设置" },
          ],
        ],
      },
      {
        text: "培训",
        icon: "https://airencai.oss-cn-beijing.aliyuncs.com/20230808/2023/8/8/417ab81a7ac74ba9900dcf004bbf4f43img (4).png",
        data: [
          [
            {
              path: "/Train/TrainingApplication",
              icon: "el-icon-data-line",
              title: "培训申请",
            },
            { path: "/Train/trainingPlan", title: "培训计划" },
            { path: "/Train/TrainingManagement", title: "课程学习" },
          ],
          [
            {
              path: "/Train/TrainingTestingAndExamination?data=5",
              title: "考试考核",
            },
          ],
        ],
      },
      {
        text: "绩效",
        icon: "https://airencai.oss-cn-beijing.aliyuncs.com/20230808/2023/8/8/2c24b74e04a641f5a6136eaed1ae449bimg (5).png",
        data: [
          [
            {
              path: "/Performance/IndicatorLibrary",
              title: "绩效指标",
            },
            { path: "/Performance/InitiateAssessment", title: "发起考核" },
            { path: "/Performance/checkEvaluate", title: "考核评价" },
          ],
          [
            { path: "/Performance/Project", title: "绩效得分" },
            { path: "/Performance/Assessment", title: "绩效设置" },
          ],
        ],
      },
      {
        text: "薪税",
        icon: "https://airencai.oss-cn-beijing.aliyuncs.com/20230808/2023/8/8/1b6359690da642fdb79850121920e341img (6).png",
        data: [
          [
            { path: "/Salary/policy", title: "薪酬政策" },
           
            {
              path: "/Workbench/salary?data=1",
              title: "定薪申请",
            },  {
              path: "/Workbench/salary?data=2",
              title: "调薪申请",
            },
          
          ],
          [  
            
          {
            path: "/Salary/Page/SalaryAccounting",
            title: "薪酬核算",
            name: "Salary/PayGrade",
            dataTable: this.tableJump.salary.SALARY_ACCOUNTING,
          },
          {
            path: "/Salary/Page/Bonus",
            name: "Salary/PayGrade",
            title: "奖金",
            dataTable: this.tableJump.salary.BONUS,
          },
            {
              path: "/Salary/Page/Payslip",
              title: "工资条",
              name: "Salary/PayGrade",
              dataTable: this.tableJump.salary.PAYSLIP,
            },
          ]
        ],
      },
      {
        text: "入职",
        icon: "https://airencai.oss-cn-beijing.aliyuncs.com/20230808/2023/8/8/9f0e88542a71415a8538628c05660b08transfer.png",
        data: [
          [
            { path: "/Workbench/Aipageright?data=1", title: "转正" },
            { path: "/Workbench/Aipageright?data=2", title: "档案" },
            { path: "/Workbench/Aipageright?data=3", title: "合同" },
          ],
        ],
      },
      {
        text: "调离",
        icon: "https://airencai.oss-cn-beijing.aliyuncs.com/20230808/2023/8/8/9f0e88542a71415a8538628c05660b08transfer.png",
        data: [
          [
            { path: "/Workbench/Transfer?data=1", title: "人事调动" },
            { path: "/Workbench/Transfer?data=2", title: "离职" },
            { path: "/Workbench/Transfer?data=3", title: "工作交接" },
            { path: "/Workbench/Transfer?data=4", title: "离职档案" }
          ],
        ],
      },
      {
        text: "考勤",
        icon: "https://airencai.oss-cn-beijing.aliyuncs.com/20230808/2023/8/8/cefab703edb8428ba43533196f0946c0img (7).png",
        data: [
          [
            {
              path: "/Workbench/Kaoqin?data=1",
              icon: "el-icon-data-line",
              title: "排班",
            },
            {
              path: "/Workbench/Kaoqin?data=2",
              icon: "el-icon-data-line",
              title: "调换班",
            },
            {
              path: "/Workbench/Kaoqin?data=3",
              icon: "el-icon-data-line",
              title: "补卡",
            },
          ],
          [
            {
              path: "/Workbench/Kaoqin?data=4",
              icon: "el-icon-data-line",
              title: "考勤统计",
            },
          ],
        ],
      },
      {
        text: "审批",
        icon: "https://airencai.oss-cn-beijing.aliyuncs.com/20230808/2023/8/8/a18d7a66ae5745e58a10b21e4947ffcbimg (8).png",
        data: [
          [
            {
              path: "/Workbench/ExamineApprove?data=1",
              icon: "el-icon-data-line",
              title: "请（休）假申请",
            },
            {
              path: "/Workbench/ExamineApprove?data=2",
              icon: "el-icon-data-line",
              title: "加班申请",
            },
            {
              path: "/Workbench/ExamineApprove?data=3",
              icon: "el-icon-data-line",
              title: "外出申请",
            },
          ],
          [
            {
              path: "/Workbench/ExamineApprove?data=4",
              icon: "el-icon-data-line",
              title: "出差申请",
            },
            {
              path: "/Workbench/ExamineApprove?data=5",
              title: "增岗增编",
            },
          ],
        ],
      },
    ];
  }
}
let Codes = new ArcCode();
export let ManpowerMenu = Codes.ManpowerMenu;
export let CheckType = Codes.CheckType;
export let timeResult = Codes.timeResult;
export let tableJump = Codes.tableJump;
