import axios from "./airencaiHttp";
//获取树形图权限菜单
export function getTreeMenu(data) {
    return axios.get("/Menu/getTreeMenu", data)
}


//创建权限模板
export function createPermissionTemplate(data) {
    return axios.post("/Menu/createPermissionTemplate", data)
}
//获取权限模板
export function getPermissionTemplate(params) {
    return axios.get("/Menu/getPermissionTemplate", { params })
}

//更新或者创建个人权限
export function insertOrUpdatePermissionTemplate(data) {
    return axios.post("/Menu/insertOrUpdatePermissionTemplate", data)
}

//批量模板更新或者创建个人权限
export function batchInsertOrUpdate(data) {
    return axios.post("/Menu/batchInsertOrUpdate", data)
}


//获取权限模板
export function getPermissions(userId) {
    return axios.get("/Menu/getPermissions/" + userId)
}

//获取权限模板
export function getUserPermissions(userId) {
    return axios.get("/Menu/getUserPermissions/" + userId)
}

//更新权限模板
export function updatePermissionTemplate(data) {
    return axios.post("/Menu/updatePermissionTemplate", data)
}
//删除权限模板
export function deletePermissionTemplate(id) {
    return axios.post("/Menu/deletePermissionTemplate/" + id)
}


