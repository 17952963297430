import Zlib from 'zlib'
// import Base64
export let  pako_zip  =function(str){
    if(str instanceof String){

    }else{
        str = JSON.stringify(str)
    }
    if(str == ""){
        return
    }
   //zlib压缩
   var deflate = Zlib.deflateSync(str, {level: 9})
   return deflate.toString('base64')
}

  export let pako_unzip = function(info,e=false){
  // zlib解压
  if(info==null ||info==undefined || info=="" ){return null};

  let buffer = Buffer.from(info, 'base64')
  var inflate = Zlib.inflateSync(buffer)
  if(e){
    return inflate.toString();
  }else{
    return JSON.parse(inflate.toString()) 
  }
  }
